import img1 from "../assets/images/leadersPage-hero-img_min.jpg";
import img2 from "../assets/images/educatorsPage-hero-img_min.jpg";
import img3 from "../assets/images/coparatesPage-hero-img_min.jpg";
import img4 from "../assets/images/seekersPage-hero-img_min.jpg";
import img5 from "../assets/images/studentsPage-hero-img_min.jpg";

const ExperienceDropdownItems = [
  {
    id: 1,
    path: "/services/mindfulness-for-leaders",
    imageSrc: img1,
    altText: "Leaders",
    label: "Leaders",
  },
  {
    id: 2,
    path: "/services/mindfulness-for-educators",
    imageSrc: img2,
    altText: "Educators",
    label: "Educators",
  },
  {
    id: 3,
    path: "/services/mindfulness-for-corporates",
    imageSrc: img3,
    altText: "Corporates",
    label: "Corporates",
  },
  {
    id: 4,
    path: "/services/mindfulness-for-seekers",
    imageSrc: img4,
    altText: "Seekers",
    label: "Seekers",
  },
  {
    id: 5,
    path: "/services/mindfulness-for-students",
    imageSrc: img5,
    altText: "Students",
    label: "Students",
  },
];

export default ExperienceDropdownItems;
